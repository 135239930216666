import { useStaticQuery, graphql } from 'gatsby'

const PRIVACY_POLICY_QUERY = graphql`
  query {
    content: contentfulArticle(
      contentful_id: { eq: "1LsZE1G8oq51WIwRhh8rxV" }
    ) {
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
          }
        }
      }
      tags
    }
  }
`

const usePrivacyPolicy = () => {
  const { content } = useStaticQuery(PRIVACY_POLICY_QUERY)
  return content
}

export default usePrivacyPolicy
